<template>
  <v-col
      cols="12"
      lg="8"
      md="8"
      sm="12"
      xl="6"
  >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  name: "ColBlock"
}
</script>

<style scoped>

</style>
